.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@media (max-aspect-ratio:1/1) {
  .desktopItem {
    display: none !important
  }
}


@media (min-aspect-ratio:1/1) {
  .phoneItem {
    display: none !important
  }
}